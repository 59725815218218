import Header from "./Header";
import Footer from "./Footer";
import { RenderElement } from "./common";
import useWindowDimensions from "../hooks/useWindowDimensions"
import { mediumScreen } from "./utils"
import { useContext } from "react"
import GlobalStateContext from "./GlobalStateContext"
import { useEffect } from 'react'

function BlogPost_1() {

    useEffect(() => {
        document.title = "Juleo | Blog"; // Set the title dynamically
      }, []); // Empty dependency array means it runs only on component mount

    return (
        <>
            <Header HeaderBg="bg-[#F5F3F1]" ForegroundBg="text-wine-100" />

            <div className="px-[8vw] flex-col items-center justify-center md:justify-normal md:px-[16vw] space-y-2 text-sm  bg-[#F5F3F1] ">
                <div className="flex-col pt-4">
                    <h1 className="font-Frank text-3xl md:text-[56px] lg:text-[52px] leading-none text-wine-100 font-medium md:tracking-[-2.56px]">Why dating & matrimony apps don't work, explained in 2 words…</h1>
                </div>


                <h2 className="text-2xl md:text-4xl font-DMSans py-4">Bazaar and Chat!</h2>





                {/* <div className="flex flex-row items-center">
                <div className="mt-6 py-2 bg-wine-20 w-fit px-6 rounded-full "><p
                className=" text-3xl text-wine-100 md:text-4xl font-medium font-DMSans ">Bazaar</p></div>
                
                <p>and</p>
                <div className="mt-6 py-2 bg-wine-20 w-fit px-6 rounded-full "><p
                className=" text-3xl text-wine-100 md:text-4xl font-medium font-DMSans ">Chat</p></div>
                </div> */}




                {/* <p
                className="pt-2 text-xl text-wine-80 md:text-4xl font-style: italic font-DMSans">
                    explained in 2 words…</p> */}


                <div className="font-DMSans text-base md:text-lg space-y-8">
                    <p>Ok, so we’ll take a few more than 2 words to explain if we’ve piqued your curiosity 🙂</p>

                    <div className="space-y-4">

                        <div className="py-1 bg-wine-20 w-fit px-6 rounded-full "><p
                            className=" text-2xl text-wine-100 md:text-4xl font-medium font-DMSans ">Bazaar</p></div>

                        <p>Indulge me with a history lesson: Match.com was the OG in 1995 that visualized a dating/matrimony site/app as a catalogue of men & women i.e., a public bazaar. Tinder added their super successful spin on the bazaar model and Bumble their own, but the bazaar model has essentially stayed the course for nearly 30 years as the dominant paradigm in this space.
                        </p>

                        <div className="font-DMSans text-base md:text-lg space-y-2 pt-3">


                            <p className="font-bold">The bazaar model has a bunch of user experience problems:
                            </p>

                            <ol className="list-decimal pt-2  space-y-8">
                                <li className="">
                                    <p><b>Privacy & Inefficiency:</b> Indian women feel uncomfortable putting themselves out there on a bazaar and prefer private matchmaking channels, which is what is leading to skewed gender ratios (~80 men for 20 women) on dating & matrimony apps. Interestingly, offline matchmakers which are a more private channel, see an opposite gender ratio.
                                    </p>

                                    <p className="pt-3">Skewed gender ratio causes inefficiency:</p>

                                    <ul className="list-disc list-inside space-y-4 pt-3">
                                        <li>
                                            Leads to a problem of plenty for women who get inundated by matches from men who find them on the bazaar. They are hence overwhelmed by quantity and underwhelmed by quality, even though good men they’d match with exist as needles in the haystack. And another second order effect is ghosting, say when a woman is chatting with 10 guys and then eventually picks 1, finding it too tedious to “break up” with the other 9.
                                        </li>
                                        <li>
                                        The odds of success hence drop for men given the high degree of competition to even get noticed. As someone put it: Both men and women are dying of thirst on these apps. Men because it’s a desert. Women because its an ocean, water water everywhere but not a drop to drink.
                                        </li>
                                        <li>
                                        2 out of 3 users of dating & matrimony apps in the top 8 Indian cities have hence never met anyone in person from these apps, it's crazy!
                                        </li>
                                    </ul>

                                </li>

                                <li>
                                    <p><b>Mental Health Toll:</b> In a bazaar everyone aims out of their league v/s being realistic (but human) and then gets disappointed on being rejected. Bazaars further give the false illusion of there being plenty of fish (though most people you see don’t match your criteria and vice versa) and so lack of success hurts even more. Also bazaars (essentially feeds like Instagram/YouTube, LinkedIn or Google Discover) lead to timepass behaviour - where users often come just for validation (say after a bad day at work) or just to check out the field from afar, without any real intention of meeting people. This endless cycle of rejection that this causes has led to a mental health toll. Match Group (owner of Tinder and Hinge) is being sued in the US for this, and 48% of singles in India’s top 8 cities said dating & matrimony apps have adversely impacted their mental health.  
                                    </p>
                                </li>

                                <li>
                                    <p><b>Scams:</b> Bazaars (endless scroll of profiles) require a large no. of users. That is why apps haven’t made verification mandatory because if they did, they worry users would drop and they won't have enough to sustain a bazaar. This has paved the way for a whole gamut of scams that the media is now reporting, apart from users’ blatant lying about their particulars on their profiles. 70%+ of Indian women and 50%+ of Indian men (yes, men too!) feel the apps lack adequate security & privacy.
                                    </p>
                                </li>
                            </ol>
                        </div>


                        <div className="font-DMSans text-base md:text-lg space-y-2 pt-4">


                            <p className="font-bold">The bazaar model causes a business model problem as well:
                            </p>

                            <p>
                            The high no. of users a bazaar needs to enable near endless scroll also necessitates the apps to have a freemium model. What now happens is that the most popular men and women use the free tier, match and leave. So the apps fail to monetize their happiest users. Instead they monetize users who have got left behind and pay for virtual goods like superlikes or roses to get noticed amongst the crowd. Now the user is smart, and you can only extract so much money from an unhappy user you can’t deliver success to. So this is an inherently flawed business model, right? No wonder the apps are struggling to get more than 5-15% of users to pay them and not seeing revenue growth commensurate with the Street’s expectations leading to their stock prices being at historic lows.

                            </p>
                        </div>


                    </div>


                    <div className="space-y-4">

                        <div className="py-1 bg-wine-20 w-fit px-6 rounded-full "><p
                            className=" text-2xl text-wine-100 md:text-4xl font-DMSans ">Chat</p></div>

                        <p>Chat (ala Whatsapp) has been the most empowering tool in many areas, both personal and business, but not here.
                        </p>

                        <div className="font-DMSans text-base md:text-lg space-y-2 pt-3">


                            <p className="font-bold">Chat has a bunch of user experience problems:
                            </p>

                            <ol className="list-decimal pt-2  space-y-8">
                                <li className="">
                                    <p><b> Miscommunication happens leading to bad decisions:</b> In high trust & emotion use cases like this one, human beings are meant to see 4 signals - what is said, what tone it is said in, what expressions it is said with and what is the body language. Chat, Audio Call, Video Call and In Person Meeting are hence in that order progressively better ways to communicate and evaluate a potential partner. With chat being the primary/default mode on the apps, users are hence making bad decisions to meet or not based on incomplete information and limited/misinterpreted signals. They are often just playing uneccesaary mindgames.
                                    </p>
                                </li>

                                <li>
                                    <p><b>Slows down the process and accentuates mental health toll:</b> Given meeting someone can actually fast track both good and bad decisions to progress, chat unnecessarily prolongs the process. This can lead to an escalation of emotional attachment and hope, that can come crashing down after the first meeting leading to disappointment and emotional drain.
                                    </p>
                                </li>

                                <li>
                                    <p><b>Gives the opportunity to be Inauthentic:</b> As people often get their friends (or now ChatGPT) to communicate, chat then leads to first meeting surprises when the person ends up being boring or having poor communication skills. Worse still, a bunch of scams happen with the scammer hiding behind chat.
                                    </p>
                                </li>
                            </ol>
                            <p className="py-6">
                            Now chat does have one very important function, giving users the ability to gauge if the person they are talking to is safe to meet and their type, as imperfect as it may be. However there are other ways to give people comfort to meet without chat e.g., prior Govt ID verification, profile videos that show personality, the option to take a friend/family along, video/audio calls via the app, Uber style feedback & Trust Scores etc. which do away with the cons.

                            </p>
                        </div>


                        <div className="font-DMSans text-base md:text-lg space-y-2 pt-4">


                            <p className="font-bold">Chat causes a business model problem as well:
                            </p>

                            <p>
                            When users chat, they at some point exchange Insta handles/phone nos., and leave the platform. What is essentially happening is that this is a lead gen model which does not close the loop to the first meeting (let alone a relationship). While every category on the consumer internet has made the transition from lead gen to fulfilment (i.e., classified to commerce), this is one category that is still stuck in the classifieds paradigm. That is why in $ terms this market is a lot smaller at ~$10B revenue globally than you would expect outside-in given how primal a use case this is and hence the latent propensity to pay for it. Only a classified quantum of value is being created and captured v/s a commerce quantum that a fulfilment model that delivered meetings and not just matches (leads) would capture.
                            </p>
                        </div>



                        <div className="font-DMSans text-base md:text-lg space-y-2 pt-6">


                            <p className="font-bold">To summarise, bazaar and chat are the root causes of the inefficiency, safety & privacy, and mental health issues of the apps.
                            </p>

                            <p>
                            Interestingly, human matchmakers already have mastered a user experience model that is not a bazaar and focuses on delivering the first meeting v/s a lead via chat; with verified, curated profiles and no ghosting. They have also mastered a business model where monetization is not an issue as they charge high upfront fees (commerce and not classified quantum of revenue) to both men and women which align incentives - if the matchmaker finds you the one in the first candidate you meet, more power to you as you did not have to go through the inefficiency of the apps. And more power to the matchmaker as their LTV/CAC worked out at the outset leading to a profitable business.This is embracing the category truth that “the better your product is, the faster it will churn users”, something the monthly subscription/ads based business models of the apps are fighting, leading to misaligned incentives. 
                            </p>
                        </div>


                    </div>


                    <div className="space-y-4">
                    <p className="font-bold">At Juleo <span className="text-wine-100 underline"><a href="https://join.juleo.club/web" target="_blank">https://join.juleo.club/web</a></span>, we are replicating the user experience and business model of the offline matchmaker at digital scale, to usher in a new paradigm that doesn’t just promise hope, but intends to actually deliver on it 😊
                    </p>
                    </div>                

                    <div className="space-y-4">
                    <p>Authored by Team Juleo</p>
                    </div>  

                    <div className="py-6">
                    <p className="italic text-gray-80 text-sm">Sources: Juleo-YouGov Indian Matchmaking Report 2024; foundational user research spanning users from Vadodara to Guwahati, Kochi to Jalandhar; industry interviews
                    </p>
                    </div>  
              </div>
              </div>
        </>
    );
}

export default BlogPost_1;
