import Header from "./Header";
import Footer from "./Footer";
import { RenderElement } from "./common";
import useWindowDimensions from "../hooks/useWindowDimensions"
import { mediumScreen } from "./utils"
import { useContext } from "react"
import GlobalStateContext from "./GlobalStateContext"
import { useEffect } from 'react'

function Faq() {

    useEffect(() => {
        document.title = "Juleo | Blog"; // Set the title dynamically
      }, []); // Empty dependency array means it runs only on component mount

    return (
        <>
            <Header HeaderBg="bg-[#F5F3F1]" ForegroundBg="text-wine-100" />

            <div className="px-[8vw] flex-col items-center justify-center md:justify-normal md:px-[16vw] space-y-2 text-sm  bg-[#F5F3F1] ">
                <div className="flex-col pt-4">
                <h1 className="font-Frank text-5xl md:text-[56px] lg:text-[64px] text-wine-100 font-medium md:tracking-[-2.56px]">Got questions?</h1>
                </div>

                <div className="font-body text-base md:text-lg space-y-8">
                    <p>Your friendly Genie is always here to help!{"\u00A0"}✨</p>

                    <div className="space-y-4">
                        <div className="font-body text-base md:text-lg space-y-2 pt-3">
                            <ol className="list-decimal pt-2  space-y-8">
                                <li className="">
                                    <p><b>
                                    Why does Juleo ask for Aadhaar or Government ID? Does the Government allow it?
                                    </b></p>
                                    <p>Let’s be real, dating and matrimony apps can be a jungle of fake identities and exaggerated bios, which can sometimes lead to pretty scary situations (and scams). That’s why at Juleo, we make sure every Member is verified with a Government ID. It’s our way of keeping the Club safe and trustworthy. Honestly, we think it should be a rule for all companies in this space, and we already use trusted Govt of India services like DigiLocker and VAHAN to get it done.</p>

                                    <p className="pt-3">And don’t sweat it, we’re not snooping on your Aadhaar number. We only grab the basics: your name, birthdate, parents’ names, address, and your Govt ID pic, all with your permission, to make sure you’re part of a legit, safe community. Plus, we’re ISO certified and fully compliant with GDPR and CCPA, so your privacy is locked down tight to the highest global standards. It’s just like submitting your ID proof when you join any exclusive Club—nothing unusual, right?</p>

                                </li>

                                <li>
                                    <p><b>Why does Juleo have a waitlist? And why am I on it so long?</b>
                                    </p>
                                    <p>Think of Juleo as a Trusted Singles Club, not your typical dating app or matrimony site. Just like any exclusive Club (say, a Gymkhana Club), we have a waitlist for Membership applications. The waitlist isn’t there to keep you waiting forever, though—it’s there to make sure that when you do get in, there’s a good number of fellow Members ready and excited to meet you!
                                    </p>
                                    <p className="pt-3">We know waiting can be tough, but trust us, you’re still in line, and we’re working hard to make sure it’s worth the wait. Rest assured, your data is totally safe with us—we’re ISO certified and fully compliant with GDPR and CCPA. While you’re on the list, you can refer friends to Juleo! It helps grow the pool of Members and might even speed up your entry.
                                    </p>
                                </li>

                                <li>
                                    <p><b>Why is Juleo a paid service?</b>
                                    </p>
                                    <p>Juleo is a premium service, and we’re upfront about it—because great things aren’t free, right? We charge a price that reflects the value we offer, and while we’re conscious capitalists, there are real costs behind building something as special as this. From our amazing team to top-tier tech and reaching the right Members, it all adds up.
                                    </p>
                                    <p className="pt-3">Being a paid service also helps keep things serious, with less ghosting (yay!). Let’s face it, when you pay for something, you tend to value it more. The trial costs less than a fancy dinner or an evening out—so, pretty good investment in your future, don’t you think? Plus, before you dive in, we let you convince yourself that there are other Members of your type in the Club and that they are real as you have met at least one of them.
                                    </p>
                                </li>

                                <li>
                                    <p><b>Why am I not seeing other Members even though I am in the Club?</b>
                                    </p>
                                    <p>Welcome to Juleo! You won’t be scrolling through a bazaar of profiles like on traditional dating and matrimony apps. Here’s how it works:
                                    </p>
                                    <p className="pt-3">Your Personal Matchmaking Genie (yep, virtual, not human!) does the heavy lifting. For our women Members, it only shows you profiles that are aligned with your preferences.  You can choose to share yours if you're interested in meeting a man, in person or via video/audio through the Juleo app, with full privacy and control.
                                    </p>
                                    <p className="pt-3">And this works great for men too because there is no pain of endless swiping or the stress of being witty on chat.  We save them their precious time & energy as they straight up get meeting invites from women who are already keen to meet them. A win-win that does away with both the inefficiency and mental health toll inflicted by dating & matrimony apps!
                                    </p>
                                    <p className="pt-3">Now, if you’re in Mumbai or Delhi NCR, you might also see Small Group Events (inspired by an Ivy League-style networking format!). If you’re not seeing an Event, it’s likely because your matching pool isn’t large enough yet. Don’t worry, though, you can help grow it by referring friends to the Club! The more, the merrier 💪
                                    </p>
                                    <p className="pt-3">Ladies, if you’re not seeing a “Match of the Day,” it means your perfect match might not be in the pool yet. You can help speed things up by referring friends or tweaking your partner criteria (sometimes, being a little less picky helps 😉).
                                    </p>
                                    <p className="pt-3">Gentlemen, if you’re not getting Meet invites, it means no woman has agreed to meet you yet. Keep the faith! You can increase your chances by referring friends and adjusting your partner criteria, while Genie keeps pitching you to the existing pool. We’re all about quality, not quantity! So hang tight, your match is on the way.
                                    </p>
                                </li>

                                <li>
                                    <p><b>What do I do if I don’t like the Matches I’m getting?</b>
                                    </p>
                                    <p>No worries, we’ve got a few tricks up our sleeve! You’ve got 3 options:
                                    </p>
                                    <p className="pt-3">
                                        <ol>
                                            <li><b>Tweak your criteria - </b>
                                            Sometimes a small adjustment can make all the difference.
                                            </li>
                                            <li className="pt-2"><b>Refer friends - </b>
                                            The more Members in the pool, the better your chances!
                                            </li>
                                            <li className="pt-2"><b>Call Genie - </b>
                                            If you’re still not feeling the love, give our helpline a ring. Genie loves feedback and will use it to improve, just like a real-life matchmaker would.
                                            </li>
                                            <li className="pt-4">
                                            We appreciate your patience as we fine-tune your experience, your Genie gets better with time time as they understand you better!
                                            </li>
                                        </ol>
                                    </p>
                                </li>

                                <li>
                                    <p><b>Why doesn’t Juleo have chat?</b>
                                    </p>
                                    <p>Chat is the main reason Singles get stuck in the app limbo! Here’s why:
                                    </p>
                                    <p className="pt-3">
                                        <ol>
                                            <li><b>Inauthentic Vibes - </b>
                                            People often get friends (or even ChatGPT!) to type for them. You meet them, and suddenly…they’re not as charming in person.
                                            </li>
                                            <li className="pt-2"><b>False Hope - </b>
                                            Chatting creates emotional attachment and high hopes, which can crash after meeting, leaving you drained.
                                            </li>
                                            <li className="pt-2"><b>Miscommunication - </b>
                                            Human connection needs more than words—it’s about tone, expressions, and body language. So, while you can chat to hide behind screens, meeting in person or even via video tells the real story!
                                            </li>
                                            <li className="pt-4">
                                            Our Juleo Members get it. Comfort to meet comes from:

                                            <ul className="list-disc">
                                                <li>
                                                Verified profiles with Govt ID and criminal checks.
                                                </li>
                                                <li>
                                                Meeting friends of friends using the power of social connections.
                                                </li>
                                                <li>
                                                A Code of Conduct that’s backed by a Trust Score and feedback after each meeting.
                                                </li>
                                                <li>
                                                The option to bring a friend (or family) to the first meeting.
                                                </li>
                                                <li>
                                                And for those who need a warm-up, a video call (even with the camera off) is an option. But trust us, the most successful Members skip that and go straight for in-person meets!
                                                </li>
                                            </ul>
                                            </li>
                                        </ol>
                                    </p>
                                </li>

                                <li>
                                    <p><b>Why can I only meet one Juleo Member at a time?</b>
                                    </p>
                                    <p>At Juleo, we believe in intentional, mindful, and respectful dating—no juggling or two-timing here! Meeting one Member at a time helps you stay focused and keeps things moving at a healthy pace. It’s all about giving each connection the attention it deserves.
                                    </p>
                                </li>

                                <li>
                                    <p><b>How is Juleo better than Hinge or Shaadi.com?</b>
                                    </p>
                                    <p >
                                        <ol>
                                            <li>
                                            <ul className="list-disc">
                                                <li>
                                                <p>
                                                Both Hinge and Shaadi.com (and let’s be honest, pretty much every other app out there) are like public bazaars—your profile is on display for all to see. At Juleo, we do things differently.
                                                </p>
                                                <p className="pt-2">
                                                Women’s profiles are private by default, and they decide who gets to see them. Men’s profiles are carefully curated and shown only to women of their type where we think there could be a match. Think of us as more of a private matchmaker than just another dating or matrimony app.
                                                </p>
                                                
                                                </li>
                                                <li className="pt-4">
                                                <p>
                                                Hinge has you filling out tons of prompts, which can feel like a lot of pressure to be witty. Plus, with ChatGPT around, are the prompts even authentic anymore? 
                                                </p>
                                                <p className="pt-2">
                                                We save you the hassle! Our Matchmaking Genie fills in fun facts about you (don’t worry, you have full control over what appears on your profile).
                                                </p>
                                            
                                                </li>
                                                <li className="pt-4">
                                                <p>
                                                In India, we know “families marry each other”, and we embrace that for Members with marriage in mind. You can bring family along to the first meeting if that’s your style.
                                                </p>
                                                <p className="pt-2">
                                                But unlike on matrimony apps, the Juleo Club Member is always the single person, so no awkward guessing about whether you’re chatting with the girl/boy or their parents—or getting those pesky parent calls at work!
                                                </p>
                                                 
                                                </li>
                                            </ul>
                                            </li>
                                        </ol>
                                    </p>
                                </li>


                            </ol>
                        </div>
                    </div>

                    <div className="border border-wine-100"></div>

                    <div className="py-4 opacity-70">
                    <p>At Juleo <span className="text-wine-100 underline"><a href="https://join.juleo.club/web" target="_blank">https://join.juleo.club/web</a></span>, we are replicating the user experience and business model of the offline matchmaker at digital scale, to usher in a new paradigm that doesn’t just promise hope, but intends to actually deliver on it 😊
                    </p>
                    </div>  
              </div>
              </div>
        </>
    );
}

export default Faq;
